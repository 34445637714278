import { createStore } from "vuex";
import { auth } from "./auth.module";
import { keepSignedIn } from "./keepSignIn.module";
import { utilities } from "./utilities.module";
import { toggleNavigation } from "./toggleNavigation.module";

const store = createStore({
  modules: {
    auth,
    keepSignedIn,
    utilities,
    toggleNavigation,
  },
});

export default store;
