/*import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import html2Pdf from 'vue3-html2pdf';
import { FontAwesomeIcon } from "./plugins/font-awesome";

createApp(App).use(store).use(html2Pdf).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
*/

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import html2Pdf from 'vue3-html2pdf';
import { FontAwesomeIcon } from "./plugins/font-awesome";

const app = createApp(App);

// Install plugins
app.use(store)
    .use(html2Pdf)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon);

// Mount the app
app.mount('#app');
